import React, { useContext, useEffect, useState } from "react";

import { AlfaCalculator, flyingItem, getOption, prorabFixPrice } from "../../HelperComponents/functions";
import { PP_CALCULATOR } from "../../HelperComponents/calculator";

import { useRouter } from "next/router";
import { AppContext } from "../../../context/appState";
import { Link, withTranslation } from "../../../i18n";
import Badges from "../../HelperComponents/Badges/Badges";

import "./ProductItem.scss";

import add_cart from "../../../public/images/add_cart.svg";

import RatingPrecision from "../../HelperComponents/RatingPrecision/RatingPrecision";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import OneCLickModal from "./modals/OneCLickModal";
import DialogCredit from "./modals/DialogCredit";
import DialogProduct from "./modals/DialogProduct";
import ERecoveryStatus from "../ERecoveryStatus";

const ProductItem = ({
    moreThanFour = false,
    productItem,
    isVisited,
    index,
    remove_fave,
    t,
    i18n: { language },
    odd = false,
    noMargin
}) => {
    const [isSafari, setIsSafari] = useState(false);

    const AlfaParts = new AlfaCalculator();

    useEffect(() => {
        calculateInstallment(1, 10);
        setIsSafari(navigator.vendor.includes("Apple"));
    }, []);

    const router = useRouter();

    const additionalURL = () => {
        let obj = { pathname: "/product/[...slug]" };
        if (router.asPath.includes("/categories/")) {
            obj.query = {};
            if (router.asPath.includes("/f/")) {
                obj.query.parent_category = router.asPath.split("/categories/")[1].split("/f/")[0];
            } else {
                obj.query.parent_category = router.asPath.split("/categories/")[1];
            }
        }
        return obj;
    };

    const [state, setState] = useState({
        dialogOpened: false,
        currentEl: {},
        productQuantity: 1,
        ipValue: "",
        ppValue: "",
        alValue: "",
        sortedCreditMonths: [["", ""]],
        creditDialogOpened: false,
        paymentMethod: productItem.discount_price > 1500 || productItem.price > 1500 ? 1 : 0,
        privatValue: PP_CALCULATOR.calculatePhys(4, productItem.discount_price || productItem.price).ppValue,
        alphaValue: AlfaParts.Calculate(productItem.discount_price || productItem.price, 10, "month"),
        innerDialogOpened: false,
        succesOneClick: false,
        clicklitePhone: false,
        loading: false
    });

    const [installmentPrivat, setInstallmentPrivat] = useState({ label: getOption(`5 ${t("мес")}.`), value: "4" });

    const [installmentAlpha, setInstallmentAlpha] = useState({ label: getOption(`10 ${t("мес")}.`), value: "9" });

    const {
        dispatchCart,
        dispatch,
        addFavorite,
        removeFavorite,
        favorites_list,
        getAllFavorites,
        setLoader
    } = useContext(AppContext);

    const changeInstallmentCheckboxPrivat = e => {
        setInstallmentPrivat(e);
        let resCalc = PP_CALCULATOR.calculatePhys(+e.value, productItem.discount_price || productItem.price);
        setState({ ...state, privatValue: resCalc.ppValue });
    };
    const changeInstallmentCheckboxAlpha = e => {
        setInstallmentAlpha(e);
        let alfaCalc = AlfaParts.Calculate(productItem.discount_price || productItem.price, +e.value + 1, "month");
        setState({ ...state, alphaValue: alfaCalc });
    };

    const calculateInstallment = (PP_months, AlphaMonths) => {
        let resCalc = PP_CALCULATOR.calculatePhys(PP_months, productItem.discount_price || productItem.price),
            alfa = AlfaParts.Calculate(productItem.discount_price || productItem.price, AlphaMonths, "month"),
            result = {
                ipValue: resCalc.ipValue,
                ppValue: resCalc.ppValue,
                alValue: alfa
            },
            sortedCreditMonths = [];

        for (let value in result) {
            sortedCreditMonths.push([value, result[value]]);
        }
        sortedCreditMonths.sort(function (a, b) {
            return a[1] - b[1];
        });

        setState({ ...state, ...result, sortedCreditMonths });
    };

    const toggleDialog = (currentEl = {}) =>
        setState({ ...state, productQuantity: 1, dialogOpened: !state.dialogOpened, currentEl });

    const toggleInnerDialog = (currentEl = {}) =>
        setState({ ...state, innerDialogOpened: !state.innerDialogOpened, succesOneClick: false });

    const handlePurchaseValue = sign => {
        if (sign === "+") {
            setState({ ...state, productQuantity: state.productQuantity + 1 });
        } else {
            setState({ ...state, productQuantity: state.productQuantity - 1 });
        }
    };

    const handleChangeValue = e => {
        const pattern = /^\d*$/g;
        const allowedCodes = [8, 9, 27, 35, 36, 37, 38, 39, 46, 110, 188];
        if (!(pattern.test(e.target.value) || allowedCodes.some(code => code === e.keyCode)) || e.target.value < 1) {
            return e.target.value.slice(0, -1);
        }
        setState({ ...state, productQuantity: Number(e.target.value) });
    };

    const addToCart = (item, quantity) => {
        let localBasket =
            JSON.parse(localStorage.getItem("cart")) !== null ? JSON.parse(localStorage.getItem("cart")) : [],
            gconfig = {
                send_to: "AW-10994701989",
                items: [
                    {
                        quantity,
                        id: item.article_number,
                        name: item.name_ua,
                        category: item.category_tree
                            .slice(-5)
                            .map(el => el.name_ua)
                            .join("/"),
                        price: item.discount_price ? item.discount_price : item.price,
                        google_business_vertical: "retail"
                    }
                ]
            };

        fbq("track", "AddToCart", {
            value: item.discount_price ? item.discount_price : item.price, //передавать цену товара
            currency: "UAH",
            content_ids: item.article_number, //передавать ИД товара
            content_type: "product"
        });

        gtag("event", "add_to_cart", gconfig);

        if (localBasket.length > 0) {
            if (localBasket.some(el => el.id === item.id)) {
                let index = localBasket.findIndex(el => el.id === item.id);

                localBasket[index].quantity += quantity;
                localBasket[index].price = (
                    localBasket[index].quantity * (item.discount_price ? item.discount_price : item.price)
                ).toFixed(2);
            } else {
                localBasket.unshift({
                    id: item.id,
                    quantity: quantity,
                    price: quantity * (item.discount_price ? item.discount_price : item.price),
                    item: item
                });
            }
        } else {
            localBasket.unshift({
                id: item.id,
                quantity: quantity,
                price: quantity * (item.discount_price ? item.discount_price : item.price),
                item: item
            });
        }
        dispatchCart(localBasket, dispatch);
        localStorage.setItem("cart", JSON.stringify(localBasket));
    };

    const handleAddFavorite = id => {
        if (favorites_list.some(el => el.id === id)) {
            removeFavorite(id, dispatch);
            getAllFavorites(dispatch);
        } else {
            addFavorite({ product: id }, dispatch);
            getAllFavorites(dispatch);
        }
    };

    const discountAmount = prorabFixPrice(productItem.price) - prorabFixPrice(productItem.discount_price);

    const browserStorageToken = typeof localStorage === "undefined" ? [] : localStorage.getItem("token");

    const outOfStock = productItem.get_availability === "Нет в наличии";

    const onOrder = productItem.get_availability === "Под заказ";

    return (
        <>
            <div
                key={productItem.id}
                className={`
                    ${moreThanFour
                        ? `${odd ? `block_for_drag widthProduct odd` : `block_for_drag widthProduct`}`
                        : "block_for_drag"
                    }${/* productItem.discount_price > 300 || productItem.price > 300 */ false ? " credit" : ""}${isVisited ? " visited" : ""
                    }${noMargin ? " card_no_margin" : ""}${outOfStock ? " out-of-stock" : ""}`}
                style={moreThanFour ? { width: "100%!important" } : {}}
            >
                {outOfStock || onOrder ? null : <Badges product={productItem} t={t} />}

                <div className="more_info">
                    <div
                        className="add_btn"
                        onClick={e => {
                            e.stopPropagation();
                            toggleDialog(productItem);
                        }}
                    >
                        <span className="font_awesome" />
                    </div>
                    <Link href={`/product/${productItem.slug}`} onClick={() => setLoader(true, dispatch)}>
                        <a className={`image_block exception`}>
                            <TransitionedBlock>
                                <img
                                    className="img_to_drag"
                                    src={
                                        productItem.thumbnail_images
                                            ? productItem.thumbnail_images[0]
                                            : productItem.images[0]
                                    }
                                    alt={`${productItem.name} - PRORAB${index !== 0 ? ` image-${index}` : ""}`}
                                    title={`${productItem.name} - Внешний вид${index !== 0 ? ` image-${index}` : ""}`}
                                />
                            </TransitionedBlock>
                        </a>
                    </Link>
                    {browserStorageToken ? (
                        <div className="hover_block_info">
                            <button
                                className={`${favorites_list &&
                                    favorites_list.length > 0 &&
                                    favorites_list.filter(elem => elem.id === productItem.id).length > 0 &&
                                    favorites_list.filter(elem => elem.id === productItem.id)[0].favorite
                                    ? "active"
                                    : ""
                                    }`}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleAddFavorite(productItem.id);
                                }}
                            >
                                <span />
                                {t("Избранное")}
                            </button>
                        </div>
                    ) : null}
                </div>
                <ERecoveryStatus
                    size="small"
                    item={productItem}
                />
                <div className="caption">
                    <Link href={`/product/${productItem.slug}`}>
                        <a className="name exception" onClick={() => setLoader(true, dispatch)}>
                            {language === "ua" ? productItem.name_ua : productItem.name}
                        </a>
                    </Link>
                    <div className="status">
                        <Link href={`/product/${productItem.slug}`}>
                            <a className={`exception`}>
                                <RatingPrecision size={isSafari ? 10 : 14} value={productItem?.rating || 0} />

                                {productItem.review_count}
                            </a>
                        </Link>
                        {outOfStock || onOrder ? null : (
                            <p>{language === "ua" ? productItem.get_availability_ua : productItem.get_availability}</p>
                        )}
                    </div>
                </div>
                <div className="offers">
                    {outOfStock || onOrder ? (
                        <div>
                            <div>
                                {outOfStock ? t("Нет в наличии") : null}
                                {onOrder ? t("Под заказ") : null}
                                <br />
                                <span className="lastPrice">
                                    {t("Последняя цена")}
                                    {": "}
                                    <span>
                                        {prorabFixPrice(productItem.price)}
                                        {` грн${productItem.unit ? `/${productItem.unit}` : ''}`}
                                    </span>
                                </span>
                            </div>
                        </div>
                    ) : productItem.discount_price ? (
                        <div className="with_discount">
                            <span className="discount_wrap">
                                <span className="old_price">{prorabFixPrice(productItem.price)} ₴</span>
                                <span className="discount_amount">-{prorabFixPrice(discountAmount)} ₴</span>
                            </span>
                            <div style={{ color: "#FF010B" }}>{prorabFixPrice(productItem.discount_price)}{` грн${productItem.unit ? `/${productItem.unit}` : ''}`}</div>
                        </div>
                    ) : (
                        <div>
                            <div>{prorabFixPrice(productItem.price)}{` грн${productItem.unit ? `/${productItem.unit}` : ''}`}</div>
                        </div>
                    )}
                    {outOfStock || onOrder
                        ? null
                        : (productItem.discount_price > 300 || productItem.price > 300) && null}
                    <div className="info_btn_box">
                        <button
                            onClick={e => {
                                e.stopPropagation();
                                if (onOrder || outOfStock) {
                                    toggleInnerDialog();
                                } else {
                                    addToCart(productItem, 1);
                                    flyingItem(e);
                                }
                            }}
                        >
                            {outOfStock || onOrder ? null : (
                                <img src={add_cart} alt="добавить в корзину image" title="PRORAB добавить в корзину" />
                            )}
                            {t(outOfStock || onOrder ? "Заказать" : "Купить")}
                        </button>
                    </div>
                </div>
                {remove_fave ? remove_fave : null}
            </div>

            <DialogProduct
                t={t}
                setState={setState}
                outOfStock={outOfStock}
                onOrder={onOrder}
                prorabFixPrice={prorabFixPrice}
                language={language}
                toggleDialog={toggleInnerDialog}
                toggleInnerDialog={toggleInnerDialog}
                handleChangeValue={handleChangeValue}
                addToCart={addToCart}
                state={state}
                handlePurchaseValue={handlePurchaseValue}
            />

            <OneCLickModal
                open={state.innerDialogOpened}
                onClose={() => {
                    toggleInnerDialog();
                }}
                isOrder={outOfStock || onOrder}
                target={[{ ...productItem, quantity: state.productQuantity }]}
            />

            <DialogCredit
                t={t}
                state={state}
                getOption={getOption}
                changeInstallmentCheckboxAlpha={changeInstallmentCheckboxAlpha}
                changeInstallmentCheckboxPrivat={changeInstallmentCheckboxPrivat}
                installmentPrivat={installmentPrivat}
                installmentAlpha={installmentAlpha}
                setInstallmentPrivat={setInstallmentPrivat}
                setInstallmentAlpha={setInstallmentAlpha}
                setState={setState}
                productItem={productItem}
                onClose={() => {
                    setState({
                        ...state,
                        creditDialogOpened: false,
                        paymentMethod: productItem.discount_price > 1500 || productItem.price > 1500 ? 1 : 0,
                        privatValue: PP_CALCULATOR.calculatePhys(4, productItem.discount_price || productItem.price)
                            .ppValue,
                        alphaValue: AlfaParts.Calculate(productItem.discount_price || productItem.price, 10, "month")
                    });
                    setInstallmentPrivat({ label: getOption(`5 ${t("мес")}.`), value: "4" });
                    setInstallmentAlpha({ label: getOption(`10 ${t("мес")}.`), value: "9" });
                }}
            />
        </>
    );
};

export default withTranslation("product")(ProductItem);
