import { useMemo } from 'react'
import erecoveryLogo from '../../../public/images/erecovery.svg'
import './styles.scss'

const ERecoveryStatus = ({ size = 'small', item }) => {
    const erecoveryStatus = useMemo(() => item.erecovery, [item])

    if (!erecoveryStatus) return null
    if (size === 'small') {
        return (
            <div className="e-recovery-status e-recovery-small">
                <img className="e-recovery-logo" src={erecoveryLogo} alt="erecovery logo" />
                <p className="e-recovery-text">єВідновлення</p>
            </div>
        )
    } else if (size === 'big') {
        return (
            <div className="e-recovery-status e-recovery-big">
                <img className="e-recovery-logo" src={erecoveryLogo} alt="erecovery logo" />
                <p className="e-recovery-text">єВідновлення</p>
            </div>
        )
    } else {
        return null
    }
}

export default ERecoveryStatus